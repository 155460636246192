<template>
	<div>
		<v-container fluid  v-if="(result == 'none' || result == 'error')">
			<v-row  align="center" justify="center">
				<v-col sm="12" md="10" lg="4" xl="4">
					<h2>登録用メールアドレスの入力</h2>
					<v-form
							ref="form"
							v-model="valid"
							lazy-validation
					>
						<v-text-field
								v-model="email"
								:rules="emailRules"
								label="メールアドレス"
								required
						></v-text-field>

						<v-col cols="12">
							<v-row justify="start" align="baseline">
							<v-checkbox
									color="green"
									:rules="[v => !!v || 'チェックされていません']"
									required
							></v-checkbox>

										<a  @click.stop="dialog_terms = true">利用規約</a>と
										<a  @click.stop="dialog_privacy = true">プライバシーポリシー</a>に
										同意します。
							</v-row>

						</v-col>

						<v-row align="center" justify="center" class="btn-reserve">
							<v-col sm="6" md="5" lg="4" xl="4">
								<v-btn
										color="#e60044"
										dark
										depressed
										tile
										block
										@click="submit"
								>
									登録する
								</v-btn>
							</v-col>
						</v-row>
					</v-form>

				</v-col>
			</v-row>

		</v-container>
		<v-container class="" fluid  v-else-if="result=='success'">
			<v-row  align="center" justify="center">
				<v-col sm="12" md="10" lg="8" xl="8" align="center" >
					<p>メールアドレスに登録用URLをお送りしました。</p>
					<p>登録用URLから会員登録を行ってください。</p>
				</v-col>
			</v-row>
		</v-container>

		<v-snackbar
				v-model="snackbar"
				:timeout="snackbarTimeout"
				color="error"
				top
		>
			{{ snackbarText }}
			<v-btn
					text
					@click="snackbar = false"
			>
				Close
			</v-btn>
		</v-snackbar>


		<!-- ___________________________________________________________ 利用規約 -->
		<v-dialog v-model="dialog_terms" width="500">
			<v-card>
				<v-card-title>利用規約</v-card-title>
				<v-card-text v-html="terms.contents_parts1">
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
							color="primary"
							text
							@click="dialog_terms = false"
					>
						閉じる
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<!-- ___________________________________________________________ プライバシーポリシー -->
		<v-dialog v-model="dialog_privacy" width="500">
			<v-card>
				<v-card-title>プライバシーポリシー</v-card-title>
				<v-card-text v-html="privacypolicy.contents_parts1">
				</v-card-text>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn
							color="primary"
							text
							@click="dialog_privacy = false"
					>
						閉じる
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>

</template>


<script>
	import fcms from "@/api/fcms.js";
	import axios from 'axios';
	let url =process.env.VUE_APP_API_ORIGIN+"/api_register/api";

	export default {
		props: { para: String},
		name: "mail_input.vue",
		data: () => ({
			axios,
			url,
			result: 'none',
			valid: false,

			// dialogの設定
			dialog_terms:false,
			dialog_privacy:false,

			//fcms情報
			fcms: [],
			terms: [],
			privacypolicy: [],


			email: '',
			emailRules: [
				v => !!v || 'メールアドレスを入力してください',
				v => /^([a-zA-Z0-9])+([a-zA-Z0-9._+-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9._-]+)+$/.test(v) || 'メールアドレスを正しく入力してください',
			],
			checkbox: false,

			//snackbar設定
			snackbar: false,
			snackbarText: '',			//	表示テキスト
			snackbarTimeout: 3000,		//	タイムアウト秒数


		}),
		methods: {
			submit () {
				if (this.$refs.form.validate()) {
					let params = new URLSearchParams();
					params.append('email', this.email);
					params.append('mode', "mailRegister_makuake");

					console.log(params)
					axios.post(url, params)
						.then(res => {
							console.log(res.data.result)
							this.result = res.data.result

							if(this.result == 'error') {
								switch(res.data.errorCode) {
									case'001':
										this.snackbarText = 'すでにメールアドレスが存在します'
										break;
								}
								this.snackbar = true
							}else {
								this.snackbarText = "メールを送信しました"
							}


						}).catch(error => {
						this.snackbarText = error
						this.snackbar = true
					})

				}else {
					console.log("err")
				}
			},

			// APIから情報を取得
			async setFcms(info) {
				this.fcms = info.data;

				console.log(this.fcms)

				// 取得エラー
				console.log(this.fcms.result);
				if(this.fcms.result != 'success') {
					this.result = 'error'
				}

				// 利用規約の取得
				if(this.fcms[0].contents == 1 && this.fcms[0].contents_bloc == 5) {
					this.terms = this.fcms[0]
				}

				// プライバシーポリシーの取得
				if(this.fcms[0].contents == 1 && this.fcms[0].contents_bloc == 4) {
					this.privacypolicy = this.fcms[0]
			}
			},

		},
		mounted() {
			fcms.fetchList(this.setFcms,1,5);
			fcms.fetchList(this.setFcms,1,4);
		}

	}
</script>

<style scoped>

</style>
